import React, { Component } from 'react';
import Trends from './Trends';

import analytics from '../../libs/analytics';
import MixpanelService from '../../libs/mixpanel';

class TrendsContainer extends Component {
  componentDidMount() {
    // in RN app you have option to view more, here we mimic that behaviour
    analytics().setCurrentScreen('learn_open');
    analytics().setCurrentScreen('learn_view_more_details_open');
    MixpanelService.track('OtherDataViewed');
  }

  render() {
    return <Trends selectedDate={this.props.selectedDate} me={this.props.me} />;
  }
}

export default TrendsContainer;
